<template>
  <div class="my-10 mevcut-container text-center">
    <div class="text-xl mb-5 text-nav_color font-bold">
      {{ t('successful_purchase_cart') }}
    </div>
    <div class="mb-8 text-lg">
      <div class="flex flex-wrap justify-center">
        <div class="font-semibold mx-0.5">
          {{ t('payment_completed_successfully_1') }}
        </div>
        <div class="font-medium">
          {{ t('payment_completed_successfully_2') }}
        </div>
      </div>

      <MevcutLink
        class="flex-shrink-0"
        :aria-label="t('aria_page_label', { page: t('notifications') })"
        :to="`/my-account/orders/${route.query.bill_id}`"
      >
        <div class="text-nav_color mx-3">{{ t('click_here') }}</div>
      </MevcutLink>
      <div class="font-medium mt-4 mb-2">
        {{ t('payment_completed_successfully_3') }}
      </div>
      <MevcutLink
        to="/materials"
        class="bg-nav_color text-text_alt_color text-sm rounded py-1 px-2"
        :aria-label="t('aria_page_label', { page: t('materials') })"
      >
        {{ t('browse', { page: t('materials') }) }}
      </MevcutLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { t } = useI18n()
const route = useRoute()

useServerSeoMeta(
  useOgMeta(
    url + '/successful-purchase-cart',
    t('successful_purchase_cart'),
    t('mevcut_description'),
    url + '/images/mevcut.webp'
  )
)

useHead({
  title: t('successful_purchase_cart'),
  link: [useSeoCanonical(url + '/successful-purchase-cart')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(
        2,
        t('successful_purchase_cart'),
        url + '/successful-purchase-cart'
      )
    ])
  ]
})
</script>
